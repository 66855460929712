import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { Loading } from 'components/shared';

import MenuManager from 'components/MenuManager';
import FlexibleVolumeChanges from 'components/FlexibleVolumeChanges';
import AppLandingPage from 'components/AppLandingPage';
import RetailLandingPage from 'components/Retail/LandingPage';
import AppHeader from 'components/shared/AppHeader';
import ComparisonTool from 'components/ComparisonTool';
import CompoundIngredientRecipeForm from 'components/CompoundIngredientRecipeForm';
import IncidentManager from 'components/IncidentManager';
import IngredientSwapper from 'components/IngredientSwapper';
import DisputedCharges from 'components/DisputedCharges';
import BehavioralDiscountTool from 'components/BehavioralDiscountTool';
import MealEditForm from 'components/MealEditForm';
import MenugridApp from 'components/Menugrid/MenugridApp';
import AcceptableShrink from 'components/Retail/AcceptableShrink';
import Categories from 'components/Retail/Categories';
import Fulfillers from 'components/Retail/Fulfillers';
import HubApp from 'hub/HubApp';

import AppConfigurationTool from 'components/AppConfigurationTool';
import { SessionContext, sessionState } from 'contexts/sessionContext';
import { BehavioralDiscountFormProvider } from 'contexts/BehavioralDiscountToolContext';
import { fetchSession } from 'services/session';
import * as Sentry from '@sentry/react';

// eslint-disable-next-line react/prop-types
const RoutesWrapper = ({ children }) => {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  return (
    <>
      {process.env.REACT_APP_ENABLE_LOGGING === 'true' ? (
        <SentryRoutes>{children}</SentryRoutes>
      ) : (
        <Routes>{children}</Routes>
      )}
    </>
  );
};

const App = () => {
  const [session, setSession] = useState(sessionState);
  useEffect(() => {
    // Necessary workaround because of a breaking change in react-scripts v5.0.1
    // Please consult IP-3939 for more information, https://github.com/Home-Chef-Tech/internal-products/issues/3939
    window.process = {
      ...window.process,
    };
    fetchSession()
      .then((resp) => {
        if (process.env.REACT_APP_ENABLE_LOGGING === 'true') {
          const user = resp.data.session;
          Sentry.setUser({ id: user.employeeId, email: user.email, username: user.name });
        }
        setSession((prevSession) => {
          return {
            ...prevSession,
            ...resp.data.session,
            featureFlags: resp.data.featureFlags,
            fetching: false,
            errorMessage: null,
          };
        });
      })
      .catch((err) => {
        setSession((prevSession) => {
          return { ...prevSession, errorMessage: err.message };
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!session.loggedIn) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item>
          {session.errorMessage ? (
            <Alert severity="error">{session.errorMessage}</Alert>
          ) : (
            <Loading topPadding={false} />
          )}
        </Grid>
      </Grid>
    );
  }

  return (
    <SessionContext.Provider value={session}>
      <Router>
        <RoutesWrapper>
          <Route path="/" element={<AppLandingPage />} />
          <Route
            path="/comparison-tool/:urlConceptId"
            element={
              <>
                <AppHeader text="Concept Version Comparison" />
                <ComparisonTool />
              </>
            }
          />
          <Route
            path="/comparison-tool"
            element={
              <>
                <AppHeader text="Concept Version Comparison" />
                <ComparisonTool />
              </>
            }
          />
          <Route exact path="/retail" element={<RetailLandingPage />} />
          <Route path="/menu-manager" element={<MenuManager />} />
          <Route path="/retail/menu-manager" element={<MenuManager />} />
          <Route path="/volume-changes" element={<FlexibleVolumeChanges />} />
          <Route path="/retail/volume-changes" element={<FlexibleVolumeChanges />} />
          <Route path="/acceptable-shrink" element={<AcceptableShrink />} />
          <Route path="/retail/acceptable-shrink" element={<AcceptableShrink />} />
          <Route path="/retail/product_lines" element={<Categories />} />
          <Route path="/retail/fulfillers" element={<Fulfillers />} />
          <Route
            path="/behavioral-discount-tool/new"
            element={
              <BehavioralDiscountFormProvider>
                <BehavioralDiscountTool />
              </BehavioralDiscountFormProvider>
            }
          />
          <Route
            path="/behavioral-discount-tool/:behavioralDiscountId"
            element={
              <BehavioralDiscountFormProvider>
                <BehavioralDiscountTool />
              </BehavioralDiscountFormProvider>
            }
          />

          <Route
            path="/incident-manager/:incidentableType/:incidentableId"
            element={<IncidentManager />}
          />
          <Route path="/ingredient-swapper/:swapOutIngredientId" element={<IngredientSwapper />} />
          <Route
            path="/ingredient-swapper/:swapOutIngredientId/:swapInIngredientId"
            element={<IngredientSwapper />}
          />
          <Route path="/ingredient-swapper/" element={<IngredientSwapper />} />
          <Route
            path="/disputed-charges"
            element={
              <>
                <AppHeader text="Disputed Charges" />
                <DisputedCharges />
              </>
            }
          />
          <Route path="/meal-edit/:mealId" element={<MealEditForm />} />
          <Route
            path="/compound-ingredient-recipe/new"
            element={<CompoundIngredientRecipeForm action="new" />}
          />
          <Route
            path="/compound-ingredient-recipe/:compoundIngredientRecipeId/edit"
            element={<CompoundIngredientRecipeForm action="edit" />}
          />
          <Route exact path="/menugrid/*" element={<MenugridApp />} />
          <Route path="/application-configurations" element={<AppConfigurationTool />} />
          <Route path="/hub/*" element={<HubApp />} />
        </RoutesWrapper>
      </Router>
    </SessionContext.Provider>
  );
};

export default App;
